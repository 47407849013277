import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layouts/UsingSparkLayout.js";
import ComponentPreview from '../../../components/ComponentPreview';
import { SprkDivider } from '@sparkdesignsystem/spark-react';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Stepper`}</h1>
    <p>{`Stepper displays a series of sequential steps
with optional descriptions and images.`}</p>
    <ComponentPreview componentName="stepper--default-story" hasReact hasHTML titleAttr="Stepper Example" mdxType="ComponentPreview" />
    <h3>{`Usage`}</h3>
    <p>{`The Stepper is useful for displaying a high-level
breakdown of a sequential process. Users can manually
move between steps to access the related descriptions and images.`}</p>
    <h3>{`Guidelines`}</h3>
    <ul>
      <li parentName="ul">{`Stepper must have a minimum of two items.`}</li>
      <li parentName="ul">{`Only one step can be selected at a time.`}</li>
      <li parentName="ul">{`Stepper should `}<em parentName="li">{`not`}</em>{` be used as a progress indicator.
There is currently no built-in way to mark a step as
"completed".`}</li>
    </ul>
    <SprkDivider element="span" additionalClasses="sprk-u-Measure" mdxType="SprkDivider"></SprkDivider>
    <h2>{`Variants`}</h2>
    <h3>{`Default`}</h3>
    <p>{`The Default Stepper is a series of steps with titles.`}</p>
    <ComponentPreview componentName="stepper--default-story" hasReact hasHTML titleAttr="Default Stepper Example" mdxType="ComponentPreview" />
    <h3>{`With Step Descriptions`}</h3>
    <p>{`Each step can have an associated text description
that is visible when the step is selected.`}</p>
    <ComponentPreview componentName="stepper--with-step-descriptions" hasReact hasHTML titleAttr="Stepper with Step Descriptions Example" mdxType="ComponentPreview" />
    <h3>{`With Dark Background`}</h3>
    <p>{`If Stepper is on a dark background, the
font colors will be inverted for better legibility.`}</p>
    <ComponentPreview componentName="stepper--with-dark-background" hasHTML hasReact titleAttr="Stepper with Dark Background Example" mdxType="ComponentPreview" />
    <h3>{`With Carousel`}</h3>
    <p>{`In addition to descriptions, each step can have an
associated image that is displayed in a carousel.
The carousel rotates so that the image associated
with the active step is always visible.`}</p>
    <p>{`On small screens, the image will stack on top of a
row of step indicators followed by the step heading
and description.`}</p>
    <ComponentPreview componentName="stepper--with-carousel" hasHTML titleAttr="Stepper with Carousel Example" mdxType="ComponentPreview" />
    <h2>{`Anatomy`}</h2>
    <ul>
      <li parentName="ul">{`Stepper must have two or more steps.`}</li>
      <li parentName="ul">{`Stepper must have a heading for each step.`}</li>
      <li parentName="ul">{`Each step may include a description.`}</li>
      <li parentName="ul">{`Each step may have an associated image
displayed in a carousel.`}</li>
    </ul>
    <h2>{`Accessibility`}</h2>
    <ul>
      <li parentName="ul">{`Users can use the UP or LEFT arrow keys
to select the previous step.`}</li>
      <li parentName="ul">{`Users can use the DOWN or RIGHT arrow
keys to select the next step.`}</li>
      <li parentName="ul">{`Users can use the HOME or END keys to
select the first or last step.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      